.delete-modal-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.233);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.delete-modal {
  width: 30rem;
  margin-bottom: 10rem;
  background-color: #fff;
  z-index: 2;
  padding: 2rem;
  border-radius: .5rem;
  text-align: center;
}