.change-password {
  padding: 2rem 0;
}

.change-password-title {
  display: flex;
  align-items: center;
}

.change-password-title h2 {
  margin: 0 2rem 0 0;
}

.change-password-field {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.change-password-field label {
  width: 10rem;
}

.change-password-field input {
  padding: .4rem .5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 15rem;
}

.change-password-error {
  color: red;
}

.change-password-success {
  color: rgb(0, 228, 0);
}

.change-password-field button {
  border: 1px solid #3696f3;
  width: 140px;
  margin-top: 1rem;
}