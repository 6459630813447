.bp-wrap {
  margin: 1rem;
  max-width: 25rem;
}

.bp-text-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 14px;
}

.bp-text-wrap h3 {
  width: 12rem;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.bp-btns {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bp-btns button {
  border: 1px solid #3696f3;
  width: 140px;
}

.layer-item {
  border-radius: .5rem;
  padding: .4rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.layer-item span {
  padding: 0;
  line-height: 1;
  font-size: 12px;
  color: #fff;
}