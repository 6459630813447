.approvals-drawer {
  padding: 4rem 1rem;
}

.approvals-drawer-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.approvals-drawer-btns {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.approvals-drawer-btns button {
  border: 1px solid #3696f3;
  width: 120px;
}

.approvals-drawer-field {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.approvals-drawer-field h4 {
  width: 8rem;
  margin: .3rem;
}

.approvals-drawer-accesses {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.approvals-drawer-accesses div{
  cursor: pointer;
  margin: 0.3rem;
}

.approvals-drawer-accesses div input{
  cursor: pointer;
  margin-right: 1rem;
}

.approvals-drawer-accesses div label{
  cursor: pointer;
}