.company-btns {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 20rem;
}

.company-btns button {
  border: 1px solid #3696f3;
  width: 140px;
}

.company-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
}

.company-table th, .company-table tr, .company-table td {
  border: 1px solid #ccc;
  padding: .5rem 1rem;
}


.company-table thead tr {
  background-color: rgba(204, 204, 204, 0.418);
}

.company-table tbody tr {
  cursor: pointer;
}

.company-table tbody tr:hover {
  background-color: rgba(204, 204, 204, 0.206);
}