.dashboard {
  padding: 1rem;
}

.dashboard-wrap {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-item {
  border: 1px solid #cccc;
  border-radius: .5rem;
  padding: .5rem;
  margin: .5rem;
  width: 15rem;
  height: auto;
}

.dashboard-item h4 {
  margin: 0 0 .5rem 0;
}

.dashboard-item span {
  font-weight: bold;
  font-size: 2rem;
  color: #3696f3;
}